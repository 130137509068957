import Vue from 'vue'
import App from './App.vue'
import request from './utils/request'
import './css/style.css'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.request = request
Vue.prototype.$axios = axios


new Vue({
  render: h => h(App)
}).$mount('#app')
