<template>
  <div class="container">
    <!-- <div class="content">
      <h1>UnityDesk</h1>
      <p class="tagline">Make teamwork your best work.</p>
    </div> -->
    <!-- <div class="auth">
      <button @click="goToLogin" class="btn">Log In</button>
      <button @click="goToRegister" class="btn btn-alt">Sign up</button>
    </div> -->
    <div class="uploadFile" >
      <input type="file" @change="handleFileUpload" />
      <button @click="submitFile" class="btn">上传并下载简历</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToLogin() {
      alert("跳转到登录界面");
      // 逻辑来处理跳转到登录界面
    },
    goToRegister() {
      // 逻辑来处理跳转到注册界面
    },
    handleFileUpload(event) {
        this.selectedFile = event.target.files[0];
      },
      submitFile() {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
  
        this.$axios.post('http://1.94.103.30:8080/unityDesk/parseResume', formData, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }).then(response => {
          // 处理响应，例如下载文件
          // const unit8Array = new Uint8Array(response.data);
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.selectedFile.name); // 或从响应中获取文件名
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          console.error('Error:', error);
        });
      },
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
